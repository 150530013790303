<template>
  <div class="">test</div>
</template>

<script>
import { EnumClass } from "@/models/modulePrivileges/EnumClass";

export default {
  components: {},
  data() {
    return {
      EnumClass,
    };
  },
  computed: {},
  methods: {},
  async created() {
    const a = [
      { token: "0" },
      { token: "1" },
      { token: "2" },
      { token: "3" },
      { token: "4" },
    ];
    const b = [
      { token: "0", display: "Jamsheer", $$hashKey: "008" },
      { token: "1", display: "Muhammed", $$hashKey: "009" },
      { token: "2", display: "Ravi", $$hashKey: "00A" },
      { token: "3", display: "Ajmal", $$hashKey: "00B" },
      { token: "5", display: "maleek", $$hashKey: "00B" },
    ];

    // A comparer used to determine if two entries are equal.
    const isSameToken = (a, b) => a.token === b.token;

    // Get items that only occur in the left array,
    // using the compareFunction to determine equality.
    const onlyInLeft = (left, right, compareFunction) =>
      left.filter(
        (leftValue) =>
          !right.some((rightValue) => compareFunction(leftValue, rightValue))
      );

    const onlyInA = onlyInLeft(a, b, isSameToken);
    const onlyInB = onlyInLeft(b, a, isSameToken);

    // const result = [...onlyInA, ...onlyInB];

    console.log(onlyInA);
    console.log(onlyInB);
  },
};
</script>
<style scoped></style>
